.menu-collapse {
  .ant-collapse {
    background: linear-gradient(90deg, #11203b 0%, #1e347e 51.56%, #11203b 100%);

    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .ant-collapse-item {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .ant-collapse-header {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background: transparent !important;
    padding: 5px 12px !important;
    span {
      font-size: 30px;
      color: white;
    }
  }
  .ant-collapse-content {
    background: transparent !important;
    border-top: 0.5px solid rgba($color: white, $alpha: 0.3);
    color: white;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 0;
  }
  .menu-mobile {
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      width: 100%;
      padding: 8px 17px;
      font-weight: bold;
      text-transform: uppercase;
    }
    a.active {
      background-color: #fab617;
    }
  }
}
