.content-contact {
  .layout-max.layout-container {
    padding: 20px;
    display: flex;
    height: 100%;
  }
  .container-fluid {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    min-height: calc(100vh - 250px);

    width: 100%;
  }
  .page-title {
    margin: 0 0 5px 0;
  }

  .page-title .text {
    color: balc;
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 47px;
    padding: 0;
  }

  .page-title .text:after {
    display: none;
  }
  .page-title .text:after {
    border-bottom: 18px solid #fff;
    border-right: 16px solid #fff;
  }
  @media only screen and (max-width: 992px) {
    .page-title {
      margin-bottom: 10px;
    }

    .page-title .text {
      font-size: 20px;
      line-height: 23px;
    }
  }
  .contact-title {
    color: black;
    font-size: 14px;
  }
  .date-time-info {
    width: 100%;
    display: flex;
    justify-content: center;
    .text-center {
      text-align: center;
      font-size: 14px;
      color: black;
    }
  }
  .contact-icon-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 40px;
      justify-content: center;
      align-items: center;
    }
    .contact-item {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      @media only screen and (max-width: 768px) {
        width: 50%;
      }
      .contact-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          max-width: 100px;
          @media only screen and (max-width: 768px) {
            max-width: 150px;
          }
        }
      }
      .text-center {
        margin-top: 10px;
        font-size: 16px;
        color: black;
      }
    }
  }
}
