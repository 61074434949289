.modal-login-mobile {
  height: 100vh;
  width: 100% !important;
  margin: 0;
  max-width: 100%;
  padding: 0;
  .ant-modal-content {
    height: 100%;
    border-radius: 0;
    padding: 4vw 8vw;
  }
  &.add-bank-mobile {
    .ant-modal-content {
      height: 100%;
      border-radius: 0;
      padding: 10px;
      overflow: auto;
    }
    .addBankpop > .title {
      text-align: center;
      width: 100%;
      display: block;
      font-size: 30px;
    }
    .addBankpop > .subtitle {
      text-align: center;
      width: 100%;
      display: block;
      font-size: 15px;
      margin-bottom: 20px;
    }
    .txt > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .txt-blue2 {
        font-size: 16px;
      }
    }
  }
  &.add-bank-mobile {
    .btnGroup {
      color: #fff;
      -webkit-box-direction: normal;
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      display: flex;
      margin-bottom: 10px;
      .btn-cancel {
        -webkit-box-direction: normal;
        border: none;
        outline: 0;
        cursor: pointer;
        padding: 7px 10px;
        border-radius: 5px;
        -webkit-box-flex: 1;
        flex: 1;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        min-width: 45%;
        margin: 0 5px;
        font-weight: 700;
        background: linear-gradient(180deg, #e6e6e6 0, #c2c2c2 100%);
        color: #000;
      }
      .btn-check {
        -webkit-box-direction: normal;
        border: none;
        outline: 0;
        cursor: pointer;
        padding: 7px 10px;
        border-radius: 5px;
        -webkit-box-flex: 1;
        flex: 1;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        min-width: 45%;
        margin: 0 5px;
        font-weight: 700;
        background: #284985;
        color: #fff;
        height: auto;
      }
    }
  }
  .ant-modal-close-icon {
    color: black;
  }
  .ant-input-affix-wrapper-lg {
  }
  .ant-btn-lg {
    width: 100%;
    background-color: #1f3258;
    color: white;
  }
  img {
    width: 40vw;
  }
  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  .ant-input-affix-wrapper {
    font-size: clamp(14px, 3.9062499999vw, 30px);
  }
  .txt-blue2 {
    font-size: clamp(14px, 3.9062499999vw, 30px);
  }
  .btn-check {
    font-size: clamp(14px, 3.9062499999vw, 30px);
    height: auto;
  }
}
