.news-detail {
  .rightModule {
    padding: 25px;
    margin-top: 20px;
    .infoModule {
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      border-radius: 5px;
      background: #e8e8e8;
      margin-bottom: 10px;
      padding: 10px 0;
      position: relative;
      color: #1f3258;
      article {
        padding: 40px;

        img {
          max-width: 400px;
          float: right;
          margin: 0 0 10px 20px;
          border-radius: 10px;
        }
        h1,
        h2 {
          font-size: 24px;
          margin-bottom: 15px;
          color: #1e85ec;
          font-weight: 700;
          line-height: 48px;
        }
        pre {
          white-space: break-spaces;
          overflow: unset;
        }
        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          padding: 10px;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}
